import { getClient } from "./auth";
import { GetWidgetDetails } from "./query/widget";
import { GetWidgetDetailsQuery, GetWidgetDetailsQueryVariables } from "./graphql/types";
import { getErrorMessage } from "@utils/errors";

export const getWidgetDetails = async (widgetId: string) => {
    const client = getClient();

    const { data, error, errors } = await client.query<
        GetWidgetDetailsQuery,
        GetWidgetDetailsQueryVariables
    >({
        query: GetWidgetDetails,
        variables: {
            widgetId,
        },
    });

    if (data.getWidgetDetails?.company?.id) {
        if (data.getWidgetDetails?.company?.archived) {
            throw new Error("The service is not available for this company!");
        }
        return data.getWidgetDetails.company;
    }
    if (error) throw error;
    if (errors) throw getErrorMessage(errors as unknown as Error[]);
    throw new Error("Unable to retrieve widget details!  Please contact Autocorp Technical Support");
};