import { createContext } from "react";
import { CatalogManager } from "@autocorp/ava/ctas/catalog";
import { HtmlTemplateContent } from "~/model/html";
import { productTitles } from "~/model/docs";

export interface IWidgetDesc {
    name: string;
}

export interface ITemplateState extends HtmlTemplateContent {
    assetsUrl: string;
    widgetUrl: string;
    widgetId: string;
    product: string;
    productTitles: typeof productTitles;
    sourceUrl?: string;
    catalogManager?: CatalogManager;
    fetchCtaCatalog: () => Promise<void>;
}

export interface IWidgetState extends ITemplateState, IWidgetDesc {}

const dummyAsyncFn = async () => void 0;

export const defaultWidgetState: IWidgetState = {
    assetsUrl: "",
    widgetUrl: "",
    widgetId: "",
    product: "",
    name: "",
    pointer: "",
    support: "",
    productTitles,
    fetchCtaCatalog: dummyAsyncFn,
};

export const WidgetContext = createContext<IWidgetState>(defaultWidgetState);