import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { withPaperGrid } from "../withPaperGrid";

import CreditInline from "~/assets/images/marketing/credit-inline-example.png";
import CreditInlineSidebar from "~/assets/images/marketing/credit-inline-sidebar-example.png";
import CreditBanner from "~/assets/images/marketing/credit-banner-example.png";
import CreditEmbed from "~/assets/images/marketing/credit-embed-example.png";
import CreditStaticEmbed from "~/assets/images/marketing/credit-static-embed-example.png";
import CreditButtonCta from "~/assets/images/marketing/credit-button-cta-example.png";
import TradeInline from "~/assets/images/marketing/trade-inline-example.png";
import TradeInlineSidebar from "~/assets/images/marketing/trade-inline-sidebar-example.png";
import TradeBanner from "~/assets/images/marketing/trade-banner-example.png";
import TradeEmbed from "~/assets/images/marketing/trade-embed-example.png";
import TradeStaticEmbed from "~/assets/images/marketing/trade-static-embed-example.png";
import TradeButtonCta from "~/assets/images/marketing/trade-button-cta-example.png";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
        },
        imgContainer: {
            display: "flex",
            alignItems: "center",
            minWidth: "500px",
            maxWidth: "1000px",
            height: "auto",
        },
    };
});

interface IImagePreviewProps {
    imgSrc: string;
}
const ImagePreview: React.FC<IImagePreviewProps> = ({ imgSrc }) => {
    const styles  = useStyles();
    return (
        <Box className={styles.container}>
            <Box className={styles.imgContainer}>
                <img
                    src={imgSrc}
                    alt={`install-guide-credit-inline-img`}
                    style={{
                        width: "100%",
                        objectFit: "contain",
                    }}
                />
            </Box>
        </Box>
    );
};

const CreditInlinePreview: React.FC = () => <ImagePreview imgSrc={CreditInline} />;
const CreditInlineSidebarPreview: React.FC = () => <ImagePreview imgSrc={CreditInlineSidebar} />;
const CreditBannerPreview: React.FC = () => <ImagePreview imgSrc={CreditBanner} />;
const CreditEmbedPreview: React.FC = () => <ImagePreview imgSrc={CreditEmbed} />;
const CreditStaticEmbedPreview: React.FC = () => <ImagePreview imgSrc={CreditStaticEmbed} />;
const CreditButtonCtaPreview: React.FC = () => <ImagePreview imgSrc={CreditButtonCta} />;
const TradeInlinePreview: React.FC = () => <ImagePreview imgSrc={TradeInline} />;
const TradeInlineSidebarPreview: React.FC = () => <ImagePreview imgSrc={TradeInlineSidebar} />;
const TradeBannerPreview: React.FC = () => <ImagePreview imgSrc={TradeBanner} />;
const TradeEmbedPreview: React.FC = () => <ImagePreview imgSrc={TradeEmbed} />;
const TradeStaticEmbedPreview: React.FC = () => <ImagePreview imgSrc={TradeStaticEmbed} />;
const TradeButtonCtaPreview: React.FC = () => <ImagePreview imgSrc={TradeButtonCta} />;

export const CreditInlinePreviewSection = withPaperGrid(CreditInlinePreview, {
    title: "CTA Preview",
    fullWidth: true,
});
export const CreditInlineSidebarPreviewSection = withPaperGrid(CreditInlineSidebarPreview, {
    title: "CTA Preview",
    fullWidth: true,
});
export const CreditBannerPreviewSection = withPaperGrid(CreditBannerPreview, {
    title: "CTA Preview",
    fullWidth: true,
});
export const CreditEmbedPreviewSection = withPaperGrid(CreditEmbedPreview, {
    title: "Embed Preview",
    fullWidth: true,
});
export const CreditStaticEmbedPreviewSection = withPaperGrid(CreditStaticEmbedPreview, {
    title: "Embed Preview",
    fullWidth: true,
});
export const CreditButtonCtaPreviewSection = withPaperGrid(CreditButtonCtaPreview, {
    title: "Button Preview",
    fullWidth: true,
});

export const TradeInlinePreviewSection = withPaperGrid(TradeInlinePreview, {
    title: "CTA Preview",
    fullWidth: true,
});
export const TradeInlineSidebarPreviewSection = withPaperGrid(TradeInlineSidebarPreview, {
    title: "CTA Preview",
    fullWidth: true,
});
export const TradeBannerPreviewSection = withPaperGrid(TradeBannerPreview, {
    title: "CTA Preview",
    fullWidth: true,
});
export const TradeEmbedPreviewSection = withPaperGrid(TradeEmbedPreview, {
    title: "Embed Preview",
    fullWidth: true,
});
export const TradeStaticEmbedPreviewSection = withPaperGrid(TradeStaticEmbedPreview, {
    title: "Embed Preview",
    fullWidth: true,
});
export const TradeButtonCtaPreviewSection = withPaperGrid(TradeButtonCtaPreview, {
    title: "Button Preview",
    fullWidth: true,
});