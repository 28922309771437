import { useMemo } from "react";

import { useLocation } from "@hooks/use-location";
import { useAuth } from "@api/auth";
import { parseQuery } from "@utils/url";
import { navigate } from "@utils/navigate";
import { nameValueToObj, isEqual } from "@utils/obj";
import { ProductTypes, AvaDoc, AskAvaMeta } from "~/model/docs";

export type WidgetDetails = {
    widgetId?: string;
    product?: ProductTypes,
    sourceUrl: string;
    query: Record<string, string | number>,
    prefix: string,
    version: number,
}

export const useWidgetDetails = (avaDoc: AvaDoc, fixQuery?: boolean): WidgetDetails => {
    const location = useLocation();
    const { selectedCompany } = useAuth();
    const { search } = location;

    const currentDetails = useMemo<WidgetDetails>(() => {
        const askAva = avaDoc.askAva || {} as Partial<AskAvaMeta>;
        const query = parseQuery(search);
        const {
            widgetId = "",
            product,
            sourceUrl,
        } = query;

        const mdxQuery = nameValueToObj(avaDoc.query);

        if (fixQuery && !isEqual(query, mdxQuery) && typeof window !== "undefined") {
            console.log({
                ...query,
                ...mdxQuery,
            });
            navigate({
                query: {
                    ...query,
                    ...mdxQuery,
                },
            }, {
                keepQuery: true,
                saveState: true,
                replace: true,
            });
        }

        return {
            query,
            sourceUrl: sourceUrl as string,
            widgetId: (widgetId || selectedCompany?.widgetId) as string | undefined,
            product: product as ProductTypes,
            prefix: askAva.prefix ?? "",
            version: askAva.version ?? 1,
        };
    }, [avaDoc, search, selectedCompany?.widgetId, fixQuery]);

    return currentDetails;
};