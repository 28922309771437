import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { CreateCSSProperties } from "@material-ui/core/styles/withStyles";
import { htmlToDom } from "@utils/dom";
import { useWidgetContext } from "../state";

interface IPreviewProps {
    height?: number | string;
    width?: number | string;
    media?: MediaTypes;
}

enum MediaTypes {
    full = "full",
    mobile = "mobile",
    tablet = "tablet",
}

interface IPreviewStyleProps {
    media: MediaTypes | string | number;
}

const previewPadding = 0;

const useStyles = makeStyles<Theme, IPreviewStyleProps>((theme) => ({
    frame: ({ media: displayWidth }) => {
        const getWidth = (val: string): string => {
            return `calc(${val} - ${previewPadding * 2}px)`;
        };
        const getBreakStyle = (): CreateCSSProperties<IPreviewStyleProps> => {
            switch (displayWidth) {
                case MediaTypes.full: {
                    return {
                        width: getWidth("100%"),
                    };
                }
                case MediaTypes.tablet: {
                    return {
                        width: getWidth("900px"),
                    };
                }
                case MediaTypes.mobile: {
                    return {
                        width: getWidth("300px"),
                    };
                }
                default: {
                    return {
                        width: getWidth((
                            typeof displayWidth === "number"
                                ? `${displayWidth.toString()}px`
                                : displayWidth
                        )),
                    };
                }
            }
        };
        return {
            boxSizing: "content-box",
            padding: previewPadding,
            backgroundColor: theme.palette.border.main,
            border: "none",
            ...getBreakStyle(),
        };
    },
}));

const hostHtml = (head: string, body: string, theme: Theme) => `
<html>
    <head>
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <style>
            html {
                background-color: ${theme.palette.border.main};
            }
            body {
                padding: 0;
                margin: 0;
                width: 100%;
                height: max-content;
                overflow: hidden;
                position: relative;
            }
        </style>
        ${head}
    </head>

    ${body}
</html>
`;

export const Preview: React.FC<IPreviewProps> = ({
    width,
    height,
    media,
}) => {
    const { body = "", support = "" } = useWidgetContext();
    const [frameMedia, setMedia] = useState<MediaTypes | string | number>(width || media || MediaTypes.full);
    const styles = useStyles({
        media: frameMedia,
    });
    const theme = useTheme();
    const frameRef = useRef<HTMLIFrameElement | null>(null);

    const updateFrameSize = useCallback(() => {
        const frame = frameRef.current;
        if (!frame) return;

        const doc = frame.contentDocument;
        if (!doc) return;

        const docHeight = doc.body.offsetHeight;
        // const width = doc.body.offsetWidth;

        frame.height = `${height || docHeight}px`;
        // frame.width = `${width}px`;
    }, [frameRef, height]);

    const onload = useCallback(() => {
        const frame = frameRef.current;
        if (!frame) return;

        const doc = frame.contentDocument;
        if (!doc) return;

        const body = doc.body;
        if (!body) return;

        const firstChild = body.children[0];
        if (firstChild?.tagName !== "OBJECT") {
            const obj = doc.createElement("object");
            obj.style.cssText = "position:absolute;top:0;left:0;right:0;bottom:0;z-index:-999;width:100%;height:100%;";
            obj.data = "about:blank";

            body.appendChild(obj);
            obj.contentWindow?.addEventListener("resize", updateFrameSize);
        }

        updateFrameSize();
    }, [updateFrameSize]);

    // const handleRef = (node: HTMLIFrameElement) => {
    //     if (!node) {
    //         mounted.current = false;
    //         frameRef.current = null;
    //     }
    //     if (mounted.current) return;
    //     if (node) {
    //         frameRef.current = node;
    //         mounted.current = true;
    //         const doc = node.contentDocument || null;
    //         if (!doc) return;

    //         node.onload = () => {
    //             console.log("onload");
    //             updateFrameSize();
    //         };
    //         const blob = new Blob([
    //             hostHtml(head, raw),
    //         ], { type: "text/html" });
    //         node.src = URL.createObjectURL(blob);
    //     }
    // };

    const src = useMemo(() => {
        const doc = htmlToDom(body);
        doc.querySelectorAll("script").forEach((el) => {
            el.parentNode?.removeChild(el);
        });

        const blob = new Blob([
            hostHtml(support, doc.body.outerHTML, theme).trim(),
        ], { type: "text/html" });
        return URL.createObjectURL(blob);
    }, [support, body, theme]);

    // useEffect(() => {
    //     const frame = frameRef.current;
    //     if (!frame) return;

    //     const onload = () => {
    //         const frameWindow = frame.contentWindow;
    //         if (!frameWindow) return;

    //         const frameDoc = frameWindow.document;
    //         const frameBody = frameDoc.body;
    //         if (!frameBody) return;

    //         const obj = frameDoc.createElement("object");
    //         obj.style.cssText = "position:absolute;top:0;left:0;right:0;bottom:0;z-index:-999";
    //         obj.data = "about:blank";

    //         frameBody.prepend(obj);

    //         obj.contentWindow?.addEventListener("resize", updateFrameSize);
    //     };

    //     frame.onload

    //     return () => {
    //         obj.contentWindow?.removeEventListener("resize", updateFrameSize);
    //         frameRef.current = null;
    //         mounted.current = false;
    //     };
    // }, [frameRef, updateFrameSize]);

    return (
        <iframe src={src} className={styles.frame} ref={frameRef} onLoad={onload} height={0} />
    );
};