import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import { PlacementGuideImgs } from "./types";
import { withPaperGrid } from "./withPaperGrid";

const useStyles = makeStyles((theme) => {
    return {
        gridContainer: {
            padding: theme.spacing(3),
        },
    };
});

interface PlacementGuideProps {
    placementGuide: PlacementGuideImgs;
}

export const PlacementGuide: React.FC<PlacementGuideProps> = ({
    placementGuide,
}) => {
    const styles = useStyles();

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={4}
            className={styles.gridContainer}
        >
            {(placementGuide || [])
                .map((item) => item?.childImageSharp?.fixed?.src || "")
                .map((src: string, index: number) => (
                    <Grid item key={index}>
                        <img src={src} width={300} />
                    </Grid>
                ))}
        </Grid>
    );
};

export const PlacementGuideSection = withPaperGrid(PlacementGuide, {
    title: "Placement Guide",
    grid: false,
});
