import React from "react";
import { Typography } from "~/components/Typography";
import type { MDXProviderComponentsProp, MDXProviderComponents } from "@mdx-js/react";

import Code from "~/components/Code";
import { MdxCodeFence } from "./MdxCodeFence";
import { ColFullWidth, ColHalfWidth, GridItem } from "./GridCol";
import { Spacer } from "./Spacer";
import { Preview } from "./Preview";
import { Wrapper } from "./Wrapper";
import { AssetCode } from "./AssetCode";
import { Table } from "./Table";
import { DocLink } from "./DocLink";
import { StateValue } from "./StateValue";
import { LeftRule } from "./LeftRule";
import { Display } from "./Display";

export const components: MDXProviderComponentsProp = {
    ...(["h1", "h2", "h3", "h4"] as (keyof MDXProviderComponents)[]).reduce((acc, key) => {
        const Component: React.ComponentType<any> = (props) => {
            return <Typography variant={key} component={key} {...props} />;
        };
        acc[key] = Component;
        return acc;
    }, {} as MDXProviderComponents),
    hr(props) { return <hr {...props} style={{ ...props.style, margin: 10 }} />; },
    table: Table,

    pre: MdxCodeFence,
    inlineCode: Code,

    Spacer,
    Display,
    LeftRule,

    // If you add more grid item/layout shortcodes, make sure to add them
    //  to the `gridItems` array in Wrapper.tsx.
    // It will use those to compare against React elements from the processed
    //  mdx, and if the element being compared doesn't match any of these shortcodes
    //  then it will be wrapped with FullCol
    GridItem,
    FullCol: ColFullWidth,
    HalfCol: ColHalfWidth,
    Preview,
    AssetCode,
    StateValue,
    Link: DocLink,

    wrapper: Wrapper,
};