import React, { useMemo, useRef, ReactElement, ReactNode, ReactNodeArray } from "react";

import CodeFence from "~/components/Code/CodeFence";
import { assetTemplate, isReactElement } from "./shared";
import { HtmlTemplateContent } from "~/model/html";

import { useWidgetContext } from "../state";

interface IMdxPreElement {
    mdxType: "pre";
    children?: MdxElement;
}
interface IMdxCodeElement {
    mdxType: "code";
    children?: string;
}

type MdxElement = ReactElement<IMdxPreElement | IMdxCodeElement>;

const extractCode = (children: ReactNode | ReactNodeArray): string[] => {
    const results: string[] = [];
    const procChildren = Array.isArray(children) ? children : [children];
    for (const node of procChildren) {
        if (!node || typeof node === "boolean") continue;
        if (typeof node === "string") {
            results.push(node);
            continue;
        }

        if (!isReactElement<MdxElement>(node)) continue;

        switch (node.props.mdxType) {
            case "pre":
            case "code": {
                results.push(...extractCode(node.props.children));
                break;
            }
            default: {
                const chkNode = node as ReactElement;
                if (chkNode.props?.children) {
                    results.push(...extractCode(chkNode.props.children));
                }
                break;
            }
        }
    }

    return results;
};

export interface IAssetCodeBaseProps extends CoerceType<HtmlTemplateContent, boolean> {
    title?: string;
    scroll?: boolean;
    noCopy?: boolean;
}

export const AssetCode: React.FC<IAssetCodeBaseProps> = ({
    title,
    scroll,
    noCopy = false,
    support = false,
    pointer = false,
    prerequisite = false,
    children,
}) => {
    const { widgetId, name, ...widgetAsset } = useWidgetContext();
    const error = useRef(false);

    const content = useMemo(() => {
        const output = Object.entries({
            support,
            pointer,
            prerequisite,
        }).map(([key, val]) => {
            if (val) {
                return widgetAsset[key as keyof typeof widgetAsset];
            }
            return "";
        });

        if (children) {
            const childContent = extractCode(children).join("\n");
            const [err, result] = assetTemplate(childContent, widgetAsset, true);
            if (err) {
                error.current = true;
                return err;
            }
            output.push(result);
        }

        return output.filter(Boolean).join("\n");
    }, [pointer, support, prerequisite, children, widgetAsset]);

    return (
        <CodeFence
            title={title}
            noCopy={noCopy}
            scroll={scroll}
            content={content}
            error={error.current}
        />
    );
};