import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { ClientApiDocLinks } from "@ava-docs/data/client-api-links";
import { toProperCase } from "@utils/transform";
import { withPaperGrid } from "../withPaperGrid";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            display: "flex",
            flexDirection: "column",
            width: "max-content",
            height: "100%",
        },
    };
});

interface IMarketingApiLinksProps {}

export const MarketingApiLinks: React.FC<IMarketingApiLinksProps> = (props) => {
    const styles  = useStyles();

    const content = useMemo(() => {
        return Object.entries(ClientApiDocLinks).map(([section, links], i) => {
            return (
                <List key={i} dense>
                    <ListSubheader style={{ fontSize: "25px" }}>
                        {toProperCase(section)}
                    </ListSubheader>
                    {links.map(({ name, href }, i) => {
                        return (
                            <ListItem
                                key={i}
                                component="a"
                                href={href}
                                target="_blank"
                            >
                                <ListItemIcon><ArrowRightIcon /></ListItemIcon>
                                <ListItemText
                                    primary={name}
                                    primaryTypographyProps={{ variant: "body1" }}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            );
        });
    }, []);

    return (
        <Box className={styles.container}>
            <Divider />
            {content}
        </Box>
    );
};

export const MarketingApiLinksSection = withPaperGrid(MarketingApiLinks, {
    title: "AVA Documentation",
    fullWidth: true,
});
