import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Images } from "@ava-docs/data/gallery-images";
import { Link } from "~/components/Link";
import { withPaperGrid } from "../withPaperGrid";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            flexGrow: 1,
        },
        gridContainer: {
            padding: "30px",
        },
        imgContainer: {
            display: "flex",
            alignItems: "center",
            width: "400px",
            height: "auto",
            border: "1px solid lightgrey",
            borderRadius: theme.shape.borderRadius,
            margin: "20px",
            padding: "25px",

            "&:hover": {
                cursor: "pointer",
                border: "1px solid grey",
                transform: "scale(1.03)",
                transition: "transform 0.5s",
            },
        },
    };
});

interface IMarketingGalleryProps {}

export const MarketingGallery: React.FC<IMarketingGalleryProps> = (props) => {
    const styles  = useStyles();

    return (
        <Box className={styles.container}>
            <Grid
                container
                className={styles.gridContainer}
            >
                {Images.map((imgSrc, i) => {
                    return (
                        <Grid
                            key={i}
                            item
                            className={styles.imgContainer}
                        >
                            <Link
                                to="/"
                                external
                                download
                                href={imgSrc}
                            >
                                <img
                                    key={i}
                                    src={imgSrc}
                                    alt={`marketing-img-${i}`}
                                    style={{
                                        width: "100%",
                                        objectFit: "contain",
                                    }}
                                />
                            </Link>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

export const MarketingGallerySection = withPaperGrid(MarketingGallery, {
    title: "Gallery",
    fullWidth: true,
});
