import React, { Fragment, useMemo, useCallback } from "react";
import { lighten, alpha } from "@material-ui/core/styles/colorManipulator";
import { makeStyles, withStyles, styled, StyleRules } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import clsx from "clsx";

import { InfoCard as ACInfoCard, InfoCardClasses } from "~/components/InfoCard";
import { PrimaryButton, PrimaryButtonClasses } from "~/components/Button";
import { Typography } from "~/components/Typography";

import { copyText } from "@utils/dom";

import type { ICodeContentProps, ICodeTitleProps } from "./types";

const InfoCard = withStyles((theme) => ({
    root: {
        height: "fit-content",
        margin: `${theme.spacing(2)}px 0`,
        "&:first-child": {
            marginTop: 0,
        },
        "&:last-child": {
            marginBottom: 0,
        },
    },
    body: {
        padding: theme.spacing(1.5),
        backgroundColor: theme.palette.background.default,
        overflowX: "auto",
    },
    header: {
        paddingTop: theme.spacing(.5),
        paddingBottom: theme.spacing(.5),
        minHeight: 40,
    },
} as StyleRules<InfoCardClasses>))(ACInfoCard);

const Title = styled(Box)(() => ({
    display: "flex",
    flexFlow: "row nowrap",
    height: "100%",
    alignItems: "center",
}));
const Button = withStyles((theme) => ({
    root: {
        padding: theme.spacing(.5),
        boxShadow: "none",
    },
} as StyleRules<PrimaryButtonClasses>))(PrimaryButton);
const CodeTable = styled("table")(({ theme }) => ({
    width: "100%",
    height: "100%",
    "& tr": {
        display: "flex",
    },
    "& tr > td:first-child": {
        textAlign: "right",
        flexShrink: 1,
        marginRight: theme.spacing(2),
        color: lighten(theme.palette.text.primary, .5),
        paddingRight: 4,
        borderRight: `1px solid ${alpha(lighten(theme.palette.text.primary, .5), .5)}`,
        userSelect: "none",
    },
    "& tr > td:last-child": {
        flexGrow: 1,
    },
}));

const titleStyles = makeStyles(() => ({
    title: {
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "space-between",
        alignItems: "baseline",
        width: "100%",
    },
}));

const codeStyles = makeStyles(() => ({
    codeLine: {
        margin: 0,
        padding: 0,
        whiteSpace: "pre-wrap",
    },
    codeLineNowrap: {
        whiteSpace: "pre",
    },
}));

const CodeTitle: React.FC<ICodeTitleProps> = ({ title = "", hideCopy = false, copy }) => {
    const styles = titleStyles();
    return (
        <Box className={styles.title}>
            <Title>{title}</Title>
            {hideCopy ? null : (
                <Button onClick={copy}>
                    <Typography variant="tableTitle">{"Copy"}</Typography>
                </Button>
            )}
        </Box>
    );
};

const CodeFence: React.FC<ICodeContentProps> = ({
    title = "",
    scroll = false,
    noCopy = false,
    content = "",
    error = false,
    className,
}) => {
    const styles = codeStyles();

    const Title = useMemo(() => {
        const copyHandler = () => {
            if (error) return;
            copyText(content);
        };

        return (
            <CodeTitle title={title} hideCopy={error || noCopy} copy={copyHandler} />
        );
    }, [title, error, noCopy, content]);

    const Wrapper = useCallback<React.FC>(({ children }) => (
        error ? (
            <Typography color="error" component="span">{children}</Typography>
        ) : (
            <Fragment>{children}</Fragment>
        )
    ), [error]);

    return (
        <InfoCard title={Title}>
            <Wrapper>
                <code className={className}>
                    <CodeTable>
                        <tbody>
                            {content.split("\n").map((val, i) => (
                                <tr key={i}>
                                    <td>{i}</td>
                                    <td>
                                        <pre
                                            className={clsx({
                                                [styles.codeLine]: true,
                                                [styles.codeLineNowrap]: scroll,
                                            })}
                                        >
                                            {val}
                                        </pre>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </CodeTable>
                </code>
            </Wrapper>
        </InfoCard>
    );
};

export default CodeFence;
