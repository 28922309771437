import { createView, ViewComponent, ICreateViewArgs } from "./base";
import { createDashboardLayout } from "@layouts/Dashboard";

const DashboardViewLayout = createDashboardLayout({});

export function createDashboardView<
    TContext extends Record<string, unknown> = Record<string, unknown>,
    TComponent extends ViewComponent<any> = ViewComponent<any>,
>(
    component: TComponent,
    props: ICreateViewArgs<TContext, TComponent, typeof DashboardViewLayout>,
) {
    return createView<
        TContext,
        TComponent,
        typeof DashboardViewLayout
    >(component, {
        layout: DashboardViewLayout,
        ...props,
    });
}

export type { ViewComponent } from "./base";