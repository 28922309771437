import React, { Fragment, useMemo, useRef } from "react";

import CodeFence from "~/components/Code/CodeFence";
import { assetTemplate } from "./shared";
import { useWidgetContext } from "../state";

const getFenceAttributes = (className: string): {
    language: string;
    attributes: Record<string, string>
} => {
    const [lang, rawParams = ""] = className.split(":");
    const attributes = rawParams.split("&").reduce((acc, attr) => {
        const [key, val] = attr.split("=");
        if (key) acc[key] = val ? decodeURIComponent(val) : "";
        return acc;
    }, {} as Record<string, string>);
    return {
        language: "language-" + (lang.split("language-").pop() || "text"),
        attributes,
    };
};

interface ICodeProps {
    children: string;
    className: string;
}

const MdxCodeParser: React.FC<ICodeProps> = ({ children: content = "", className }) => {
    const { widgetId, name, ...widgetAsset } = useWidgetContext();
    const error = useRef(false);

    const attributes = useMemo(() => {
        const {
            language,
            attributes: {
                title = "",
                template = null,
                scroll = null,
                noCopy = null,
                ...attrs
            },
        } = getFenceAttributes(className);
        return {
            className: language,
            title,
            template: template !== null && true,
            scroll: scroll !== null ? true : false,
            noCopy: noCopy !== null ? true : false,
            ...attrs,
        };
    }, [className]);

    const codeValue = useMemo(() => {
        const [err, result] = assetTemplate(
            content,
            widgetAsset,
            attributes.template,
        );
        if (err) {
            error.current = true;
            return err;
        }
        error.current = false;
        return result;
    }, [attributes.template, content, widgetAsset]);


    return (
        <CodeFence
            title={attributes.title}
            scroll={attributes.scroll}
            noCopy={attributes.noCopy}
            className={attributes.className}
            error={error.current}
            content={codeValue}
        />
    );
};

interface ICodeFenceProps {
    children: React.ReactElement<ICodeProps>;
}

export const MdxCodeFence: React.FC<ICodeFenceProps> = ({ children }) => {
    return (
        <Fragment>
            {React.Children
                .map<React.ReactNode, ICodeFenceProps["children"]>(children, ({ props }) => (
                    <MdxCodeParser {...props} />
                ))
            }
        </Fragment>
    );
};
