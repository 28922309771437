import { createDashboardView } from "~/model/view/dashboard";
import { CategoryNav } from "../CategoryNav";
import { SubCategoryNav } from "../SubCategoryNav";
import { TitleNav } from "../TitleNav";
import { Title, RightTitle } from "../Title";

import { DocsViewComponent } from "../types";

export const createAvaDocsView: typeof createDashboardView = (
    component,
    props = {},
) => {

    return createDashboardView(
        component,
        {
            title: "",
            ...props,
            showBack: false,
            layoutProps: {
                home: "/ava-docs",
                mainLeftNav: {
                    top: CategoryNav,
                },
                secondLeftNav: SubCategoryNav,
                topNav: {
                    left: Title,
                    right: RightTitle,
                    sub: TitleNav,
                },
            },
        },
    );
};

export type { DocsViewComponent };