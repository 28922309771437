import React from "react";

import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Typography } from "~/components/Typography";

const useStyles = makeStyles((theme) => {
    return {
        title: {
            margin: theme.spacing(0, 6),
            marginTop: theme.spacing(4),
        },
        titleFullWidth: {
            margin: theme.spacing(0, 6),
        },
        content: {
            border: `1px solid ${theme.palette.border.main}`,
            margin: theme.spacing(1),
            marginBottom: theme.spacing(4),
            minWidth: 650,
        },
        fullWidthContent: {
            padding: theme.spacing(4, 0),
        },
        gridContainer: {
            padding: theme.spacing(4, 6),
        },
    };
});

interface ContentProps {
    grid?: boolean;
}

const Content: React.FC<ContentProps> = ({ grid, children }) => {
    const styles = useStyles();
    return grid ? (
        <Grid container className={styles.gridContainer}>
            {children}
        </Grid>
    ) : (
        <>{children}</>
    );
};
interface IPaperGridProps {
    title?: string;
    grid?: boolean;
    fullWidth?: boolean;
}

export const PaperGrid: React.FC<IPaperGridProps> = ({
    children,
    ...props
}) => {
    const styles = useStyles();
    const title = props?.title || "";

    return (
        <Paper
            elevation={0}
            className={clsx(styles.content, {
                [styles.fullWidthContent]: props?.fullWidth || false,
            })}
        >
            {title && (
                <Typography
                    variant="h2"
                    className={
                        props?.fullWidth ? styles.titleFullWidth : styles.title
                    }
                >
                    {title}
                </Typography>
            )}
            <Content grid={props?.grid ?? true}>{children}</Content>
        </Paper>
    );
};

export function withPaperGrid<P>(
    WrappedComponent: React.ComponentType<P>,
    hocProps = {} as IPaperGridProps,
): React.FC<P> {
    const ComponentWithPaperGrid = (props: P) => {
        return (
            <PaperGrid {...props} {...hocProps}>
                <WrappedComponent {...props} />
            </PaperGrid>
        );
    };

    return ComponentWithPaperGrid;
}
