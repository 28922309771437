import React from "react";
import { lighten, alpha } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    code: {
        fontSize: "0.9em",
        backgroundColor: alpha(lighten(theme.palette.secondary.main, 0.5), 0.2),
        padding: `${theme.spacing(.25)}px ${theme.spacing(.5)}px`,
        borderRadius: theme.shape.borderRadius / 3,
    },
}));

const Code: React.FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>> = ({
    children,
    className,
    ...props
}) => {
    const styles = useStyles();
    return (
        <code {...props} className={clsx(styles.code, className)}>
            {children}
        </code>
    );
};

export default Code;