import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IMdxBaseRoutes } from "@autocorp/gatsby-source-askava";

import { SecondaryNavStatic, INavLink } from "@layouts/Dashboard/SecondaryNav";

import { useDocCollection } from "@ava-docs/hooks/use-doc-collection";
import { normalizeUrl } from "@utils/url";
import { escapeForRegex } from "@utils/regexp";
import { mdxBaseRoutes } from "~/meta";
import { useWidgetDetails } from "./hooks/use-widget-details";
import { DocsLayoutComponent } from "./types";

import { IDocCategory } from "~/model/docs";

type Categories = IMdxBaseRoutes["categories"];
type CategoryMeta = Categories[keyof Categories];
type CategoryKeys = keyof typeof mdxBaseRoutes["categories"];

type NavLink = Pick<INavLink, "to"> & Partial<OmitKeys<INavLink, "to">> & {
    subCategory: string;
}

const useStyles = makeStyles(() => ({
    navRoot: {
        minWidth: 225,
    },
}));

export const SubCategoryNav: DocsLayoutComponent = ({
    location,
    pageProps: {
        data,
        pageContext,
    },
}) => {
    const { prefix } = useWidgetDetails(data.mdx);

    const curDocCategory = data.mdx.frontmatter?.category;
    const curDocSubCategory = data.mdx.frontmatter?.subCategory;
    const curPath = pageContext.askAvaPath;

    const styles = useStyles();

    const base = useMemo(() => (
        location.pathname.replace(
            new RegExp(`${escapeForRegex(curPath)}.*`, "ig"),
            "",
        )
    ), [location, curPath]);

    const { catIndex } = useDocCollection();

    const subCatLinks = useMemo<NavLink[]>(() => {
        const curCat = (
            curDocCategory &&
            catIndex[prefix][curDocCategory]?.cat ||
            {}
        ) as IDocCategory;

        const categoryMeta = (
            curDocCategory &&
            mdxBaseRoutes.categories[curDocCategory as CategoryKeys] ||
            {}
        ) as CategoryMeta;

        const subCategories = curCat.subCategories || [];

        return subCategories.map(({ subCategory }) => {
            const subCatMeta = categoryMeta.subCategories[subCategory];
            const subCategoriesBase = (
                curDocSubCategory &&
                subCatMeta.base ||
                ""
            );

            const catPath = normalizeUrl(base, prefix, categoryMeta.base, subCategoriesBase);

            return {
                subCategory,
                to: {
                    path: catPath,
                    query: subCatMeta.query,
                },
                onClick: (e) => {
                    if (subCategory === curDocSubCategory) {
                        e.preventDefault();
                    }
                },
            } as NavLink;
        });
    }, [base, catIndex, curDocCategory, curDocSubCategory, prefix]);

    return (
        <SecondaryNavStatic
            title={curDocCategory}
            classes={{
                root: styles.navRoot,
            }}
            position="left"
            items={subCatLinks
                .map(({ subCategory, to, onClick, ...props }) => ({
                    to,
                    onClick,
                    label: subCategory,
                    active: subCategory === curDocSubCategory,
                    keepQuery: true,
                    saveState: true,
                    // removeQuery: showAllProducts ? [] : ["product"],
                    ...props,
                }))
            }
        />
    );
};