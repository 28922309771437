import React from "react";
import kebabCase from "lodash/kebabCase";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { AnalyticsGuide } from "@ava-docs/data/analytics-guide";
import { Typography } from "~/components/Typography";
import { withPaperGrid } from "../withPaperGrid";

const useStyles = makeStyles((theme) => {
    return {
        container: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
        },
        guideSection: {
            display: "flex",
            flexDirection: "column",
            minWidth: "550px",
            maxWidth: "850px",
            padding: "20px",
            alignItems: "center",
            margin: "0 auto",
        },
        stepContainer: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
        },
        stepImgContainer: {
            display: "flex",
            padding: "20px 0",
            width: "550px",
            height: "400px",
            margin: "0 auto",
        },
        descriptionContainer: {
            display: "flex",
            minWidth: "550px",
            maxWidth: "850px",
            padding: "20px 0",
        },
        noteContainer: {
            display: "flex",

        },
        note: {
            color: "#FF0000",
            fontStyle: "italic",
        },
    };
});

interface IMarketingAnalyticsGuideProps {}

export const MarketingAnalyticsGuide: React.FC<IMarketingAnalyticsGuideProps> = (props) => {
    const styles  = useStyles();

    return (
        <Box className={styles.container}>

            {AnalyticsGuide.map(({ title, steps }, i) => (
                <Box key={i} className={styles.guideSection}>
                    <Typography variant="h2">{title}</Typography>
                    {steps.map(({ imgSrc, description, note }, i) => {
                        const alt = `${kebabCase(title.toLowerCase())}-img-${i}`;
                        return (
                            <Box key={i} className={styles.stepContainer}>
                                {imgSrc && (
                                    <Box className={styles.stepImgContainer}>
                                        <img
                                            src={imgSrc}
                                            alt={alt}
                                            width="100%"
                                            height="100%"
                                            style={{ objectFit: "contain" }}
                                        />
                                    </Box>
                                )}
                                <Box className={styles.descriptionContainer}>
                                    <Typography>{description}</Typography>
                                </Box>
                                {note && (
                                    <Box className={styles.noteContainer}>
                                        <Typography className={styles.note}>{note}</Typography>
                                    </Box>
                                )}
                            </Box>
                        );
                    })}
                </Box>
            ))}

        </Box>
    );
};

export const MarketingAnalyticsGuideSection = withPaperGrid(MarketingAnalyticsGuide, {
    title: "Analytics Guide",
    fullWidth: true,
});
