import React from "react";

import { AvaDoc } from "~/model/docs";
import { Preview } from "./mdx/Preview";
import { PaperGrid } from "./withPaperGrid";

interface InstallPreviewProps {
    mdx: AvaDoc;
    width: string;
}

export const InstallPreview: React.FC<InstallPreviewProps> = ({
    mdx,
    width,
}) => {
    const fullWidth = width === "100%";
    const widthProp =
        fullWidth || isNaN(parseInt(width.slice(0, -2)))
            ? {}
            : { width: parseInt(width.slice(0, -2)) };

    return (
        <PaperGrid title={`${mdx?.frontmatter?.title} Preview`} grid={!fullWidth} fullWidth={fullWidth}>
            <Preview {...widthProp} />
        </PaperGrid>
    );
};
