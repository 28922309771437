import AnalyticsAddPixel from "~/assets/images/marketing/add-pixel-img.png";
import AnalyticsDeleteDialog from "~/assets/images/marketing/analytics-delete-dialog.png";
import AnalyticsPixelForm from "~/assets/images/marketing/analytics-pixel-form.png";
import AnalyticsDelete from "~/assets/images/marketing/analytics-row-delete-icon.png";
import AnalyticsEdit from "~/assets/images/marketing/analytics-row-edit-icon.png";


export const AnalyticsGuide = [
    {
        title: "The Analytics Tracking Tab",
        steps: [
            {
                description: `
                    In the "Analytics Tracking" tab under Settings, you will find the collection of all analytics pixels you have setup.
                    Each table contains pixels of a specific type, for example "Google Universal Analytics" (Google UA) or "Meta Pixel".
                `,
            },
        ],
    },
    {
        title: "Adding an Analytics Pixel",
        steps: [
            {
                imgSrc: AnalyticsAddPixel,
                description: `
                    To add an analytics pixel, click on the Add button located at the top-right side of the table.
                    For example, if you would like to add a GA4 pixel click on the "Add Google A4" button.
                `,
            },
            {
                imgSrc: AnalyticsPixelForm,
                description: `
                    A form should appear allowing you to fill out the required values of your new analytics pixel. 
                    Once filled out, click the "Add Analytics Pixel" button.
                `,
                note: "You cannot create an analytics pixel without an Analytics ID.",
            },
            {
                description: `
                    Once the new analytics pixel has been added, you will see the new pixel in the table.
                `,
            },
        ],
    },
    {
        title: "Updating an Analytics Pixel",
        steps: [
            {
                imgSrc: AnalyticsEdit,
                description: `
                    To update an analytics pixel, click on the Edit button located on the right side of an analytics row.
                `,
            },
            {
                description: `
                    A similar form should appear allowing you to change the values of your existing analytics pixel. Once 
                    you have made your changes, click the "Update Analytics Pixel" button.
                `,
            },
            {
                description: `
                    Once updated, you will see your new pixel values updated in the table.
                `,
            },
        ],
    },
    {
        title: "Deleting an Analytics Pixel",
        steps: [
            {
                imgSrc: AnalyticsDelete,
                description: `
                    To delete an analytics pixel, click on the Delete button located next to the Edit button.
                `,
            },
            {
                imgSrc: AnalyticsDeleteDialog,
                description: `
                    A dialog will appear asking you to confirm your removal of the specified analytics pixel.
                    The Analytics ID outlined in the dialog will be the pixel that is removed.
                `,
            },
            {
                description: `
                    Once deleted, the analytics pixel will be removed from the table.
                `,
            },
        ],
    },
];