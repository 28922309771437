import React, { Fragment } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import Code from "~/components/Code";
import { AssetCode, IAssetCodeBaseProps } from "./mdx/AssetCode";
import { LeftRule } from "./mdx/LeftRule";
import { ColFullWidth, ColHalfWidth, GridItem } from "./mdx/GridCol";

import { withPaperGrid } from "./withPaperGrid";


type Step = {
    title: string;
    description: string | React.ReactNode | (string | React.ReactNode)[];
    assetProps?: Partial<IAssetCodeBaseProps>;
    highlight?: string;
};

const steps: Step[] = [
    {
        title: "Step 1",
        description: [
            "Copy this Setup script, and paste it into the HTML of your site",
            <Fragment key="tip">
                <br key="break" />
                <i>
                    {"(this is commonly inside of the"}
                    <Code key={"head"}>{"<head>"}</Code>
                    {"element)"}
                </i>
            </Fragment>,
            ".",
        ],
        assetProps: {
            title: "Setup",
            prerequisite: true,
        },
        highlight:
            "*** If you already have this installed on the page, you don’t have to duplicate the code",
    },
    {
        title: "Step 2",
        description: [
            "Add this",
            <Code key={"div"}>{"<div>"}</Code>,
            "element where you wish the CTA to appear on the page.",
        ],
        assetProps: {
            title: "CTA div",
            pointer: true,
        },
    },
];

const useStyles = makeStyles({
    title: {
        width: 100,
        fontWeight: "bold",
    },
    content: {},
    desc: {
        border: "1px solid red",
    },
});

interface StepProps {
    step: Step;
}

const Step: React.FC<StepProps> = ({ step }) => {
    const classes = useStyles();

    const props = {
        ...step.assetProps,
        pointer: step?.assetProps?.pointer ?? false,
    };

    return (
        <Fragment>
            <div>
                <Box className={classes.title}>{step.title}</Box>
            </div>
            <GridItem xs={12} lg={6}>

                <Box mb={2} className={classes.content}>
                    {step.description}
                </Box>

                {Object.keys(props).length > 1 && (
                    <div>
                        <AssetCode {...props} />
                    </div>
                )}

                {step?.highlight && (
                    <Box mt={2} mb={2}>
                        <Typography color="error">
                            {step.highlight}
                        </Typography>
                    </Box>
                )}

            </GridItem>
        </Fragment>
    );
};

interface DefaultPresetProps {}

export const DefaultPreset: React.FC<DefaultPresetProps> = () => {
    return (
        <ColFullWidth>
            <LeftRule>
                {steps.map((step) => (
                    <Step step={step} key={step.title}/>
                ))}
            </LeftRule>
        </ColFullWidth>
    );
};

export const DefaultInstallGuide = withPaperGrid(DefaultPreset, {
    title: "Install",
    fullWidth: false,
});
