import { useMemo } from "react";
import { ALSCompany } from "@api/auth";

import type { AvaDoc } from "~/model/docs";
import type { HTMLTemplateCollection, HtmlTemplateContent } from "~/model/html";
import type { WidgetDetails } from "./use-widget-details";


export const useDocAssets = (
    doc: AvaDoc,
    widgetDetails: WidgetDetails,
    names: string[],
    company?: ALSCompany,
) => {

    const { askAva } = doc;
    const htmlTemplates = useMemo(() => {
        const ctaVersions: {
            [name: string]: string;
        } = {};

        return (askAva?.assets || [])
            .reduce((acc, node) => {
                if (!node) return acc;

                const {
                    type,
                    asset,
                    html,
                } = node;

                const version = asset.version || "0.0.0";
                const name = asset.name || type;
                const theme = asset.theme;

                if (theme && company?.ctaTheme !== theme) return acc;

                const checkVersion = [
                    version,
                    theme,
                ].filter(Boolean).join("_");

                const curVersion = ctaVersions[name] = (
                    ctaVersions[name] || ""
                );

                if (checkVersion > curVersion) {
                    ctaVersions[name] = checkVersion;
                    Object.assign(acc, {
                        [name]: {
                            pointer: asset.import,
                            support: asset.support,
                            prerequisite: asset.prerequisite,
                            body: html?.body,
                        } as HtmlTemplateContent,
                    });
                }
                return acc;
            }, {} as Partial<HTMLTemplateCollection>);
    }, [
        askAva,
        company?.ctaTheme,
    ]);

    const widgetAssets = useMemo<HtmlTemplateContent>(() => {
        const defaultName = (
            Object.keys(htmlTemplates).unshift()
        ) as keyof typeof htmlTemplates;
        const assetName = names.find((nm) => (
            nm in htmlTemplates
        ));
        return (
            assetName &&
            htmlTemplates[assetName] ||
            htmlTemplates[defaultName] ||
            {}
        ) as HtmlTemplateContent;
    }, [names, htmlTemplates]);

    return widgetAssets;
};