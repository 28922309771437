import CreditIpadView from "~/assets/images/marketing/credit-snapshot-ipad-view.png";
import CreditLaptopView from "~/assets/images/marketing/credit-snapshot-laptop-view.png";
import CreditMobileView from "~/assets/images/marketing/credit-snapshot-mobile-view.png";
import CreditPersonHoldingPhone from "~/assets/images/marketing/credit-snapshot-person-holding-phone.png";
import CreditToolCtaSample from "~/assets/images/marketing/credit-tool-cta-sample.png";

export const Images = [
    CreditMobileView,
    CreditIpadView,
    CreditLaptopView,
    CreditPersonHoldingPhone,
    CreditToolCtaSample,
];