import React from "react";
import { MakeGridItems } from "./MakeGridItems";

interface IDisplayProps {
    section?: string;
    mdxProps?: { show?: string[] }
}

export const Display: React.FC<IDisplayProps> = ({ section, mdxProps, children }) => {
    const shouldDisplay = section && mdxProps?.show?.includes(section);
    return shouldDisplay ? <MakeGridItems>{children}</MakeGridItems> : null;
};
