import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { DocsLayoutComponent } from "./types";


export const useStyles = makeStyles(({
    typography,
}) => ({
    topNavTitle: {
        ...typography.title,
        lineHeight: "44px",
    },
    topRightTitle: {
        ...typography.navWidget,
        lineHeight: "44px",
    },
}));

export const Title: DocsLayoutComponent = ({
    pageProps: {
        data,
    },
}) => {
    const styles = useStyles();

    const curDocCategory = data.mdx.frontmatter?.category;
    const curDocSubCategory = data.mdx.frontmatter?.subCategory;

    return (
        <Fragment>
            <Box
                display="flex"
                alignItems="center"
                className={styles.topNavTitle}
            >
                {curDocSubCategory}
            </Box>
        </Fragment>
    );
};

export const RightTitle: DocsLayoutComponent = ({
    location,
}) => {
    const styles = useStyles();
    const companyName = location.state?.companyName;

    return (
        <Fragment>
            <Box
                display="flex"
                alignItems="center"
                className={styles.topRightTitle}
            >
                {companyName}
            </Box>
        </Fragment>
    );
};