import React, { Fragment, useMemo } from "react";

import { makeStyles } from "@material-ui/core/styles";
import NoSsr from "@material-ui/core/NoSsr";

import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import { useAuth } from "@api/auth";
import { useDocAssets } from "@ava-docs/hooks/use-doc-assets";
import { useWidgetDetails } from "@ava-docs/hooks/use-widget-details";
import { WidgetProvider } from "@ava-docs/state/provider";

import { createAvaDocsView } from "@ava-docs/view";
import { CategoryNav } from "@ava-docs/CategoryNav";

import { IdModal } from "@ava-docs/IdModal";
import { FillRequired } from "@ava-docs/FillRequired";
import { InstallPreview } from "@ava-docs/InstallPreview";
import { PlacementGuideSection } from "@ava-docs/PlacementGuideSection";
import { PlacementGuideImgs } from "@ava-docs/types";
import { DefaultInstallGuide } from "@ava-docs/DefaultInstallGuide";

import { withPaperGrid } from "@ava-docs/withPaperGrid";
import { useCustomComponent } from "@ava-docs/custom-components";

import type { DocsViewComponent } from "@ava-docs/types";

const WrappedMDXRenderer = withPaperGrid(MDXRenderer);

const useStyles = makeStyles((theme) => {
    return {
        contentContainer: {
            padding: `${
                theme.dashboard?.innerContent?.paddingTop || 0
            }px 20px 0`,
            width: "100%",
            height: "100%",
            overflowY: "auto",
        },
    };
});

const AvaDocs: DocsViewComponent = ({ data, location, navigate }) => {
    const styles = useStyles();

    const frontmatter = (data.mdx.frontmatter || {}) as (
        Partial<NonNullable<typeof data.mdx.frontmatter>>
    );

    const {
        category,
        subCategory,
        title,
        name,
        group,
        type,
    } = frontmatter;

    const installPreview = frontmatter.installPreview ?? false;
    const installPreviewWidth = frontmatter.installPreviewWidth ?? "";
    const installGuide = frontmatter.installGuide ?? false;
    const installGuidePreset = frontmatter.installGuidePreset ?? "";
    const usageGuidelines = frontmatter.usageGuidelines ?? false;

    const CustomComponent = useCustomComponent(frontmatter.customComponent);

    const placementGuide: PlacementGuideImgs = data.mdx?.placementGuide;

    const required = useMemo(
        () => data.mdx.frontmatter?.require || [],
        [data.mdx],
    );

    const names = name?.split(/,\s*/) || [];

    const { selectedCompany } = useAuth();
    const widgetDetails = useWidgetDetails(data.mdx, true);
    const widgetAssets = useDocAssets(data.mdx, widgetDetails, names, selectedCompany);

    const { widgetId, product, sourceUrl } = widgetDetails;

    const hasRequired = useMemo(
        () =>
            required.every(
                (rkey) => !!widgetDetails[rkey as keyof typeof widgetDetails],
            ),
        [widgetDetails, required],
    );

    return (
        <WidgetProvider
            name={[category, subCategory, title].join("-")}
            widgetId={widgetId}
            product={product}
            sourceUrl={sourceUrl}
            version={widgetDetails.version}
            group={group}
            type={type}
            {...widgetAssets}
        >
            <div className={styles.contentContainer}>
                <NoSsr>
                    <IdModal location={location} avaDoc={data.mdx}>
                        {required.length > 0 && (
                            <FillRequired
                                required={required! as string[]}
                                hide={false}
                                navigate={navigate}
                            />
                        )}

                        {hasRequired && (
                            <Fragment>
                                <CustomComponent />

                                {installPreview && (
                                    <InstallPreview
                                        mdx={data.mdx}
                                        width={installPreviewWidth}
                                    />
                                )}

                                {installGuide &&
                                    (installGuidePreset === "default" ? (
                                        <DefaultInstallGuide />
                                    ) : (
                                        <WrappedMDXRenderer
                                            show={["install-guide"]}
                                            title="Install"
                                        >
                                            {data.mdx.body}
                                        </WrappedMDXRenderer>
                                    ))}

                                {placementGuide && (
                                    <PlacementGuideSection
                                        placementGuide={placementGuide}
                                    />
                                )}

                                {usageGuidelines && (
                                    <WrappedMDXRenderer
                                        show={["usage-guidelines"]}
                                        title="Usage Guidelines"
                                    >
                                        {data.mdx.body}
                                    </WrappedMDXRenderer>
                                )}
                            </Fragment>
                        )}
                    </IdModal>
                </NoSsr>
            </div>
        </WidgetProvider>
    );
};

export const query = graphql`
    query DocsBySlug($id: String!) {
        mdx(id: { eq: $id }) {
            id
            body
            excerpt
            frontmatter {
                category
                subCategory
                title
                name
                customComponent

                group
                type

                installPreview
                installPreviewWidth

                installGuide
                installGuidePreset

                usageGuidelines

                placementGuide
                require
            }
            askAva {
                slug
                prefix
                version
                assets {
                    id
                    group
                    type
                    asset {
                        assetId
                        name
                        theme
                        version
                        import
                        support
                        prerequisite
                    }
                    html {
                        body
                    }
                }
            }
            placementGuide {
                childImageSharp {
                    fixed(width: 300) {
                        src
                    }
                }
            }
            query {
                name
                value
            }
        }
    }
`;

export default createAvaDocsView(AvaDocs, {
    title: "Docs",
    internalScroll: true,
    internalPad: true,
    // viewWrapper: DocsWrapper,
    layoutProps: {
        mainLeftNav: {
            top: CategoryNav,
            bottom: undefined,
        },
    },
});
