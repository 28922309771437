import React, { Fragment, useMemo, useRef, useState, useCallback } from "react";

import { makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import clsx from "clsx";

import { Typography } from "~/components/Typography";
import { Input } from "~/components/Input";
import { PrimaryButton } from "~/components/Button";

import { useWidgetContext } from "@ava-docs/state";
import { IComponentNavigateFn, INavigateOptions } from "@utils/navigate";

const navOptions: INavigateOptions = {
    keepQuery: true,
    saveState: true,
    replace: true,
};

interface ISourceUrlProps {
    hide?: boolean;
    required: string[];
    navigate: IComponentNavigateFn;
}

type RequireParams = "sourceUrl";
type Errors = Record<RequireParams, string>;

const useStyles = makeStyles<Theme, Errors>((theme) => {
    const padding = 20;
    return {
        contentContainer: {
            paddingBottom: padding,
            width: "100%",
        },
        content: {
            border: `1px solid ${theme.palette.border.main}`,
            minWidth: 650,
            padding: 20,
        },
        tooltip: {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.default,
            ...theme.typography.body1,
            boxShadow: theme.shadows[1],
            fontSize: 14,
        },
        tooltipError: {
            color: theme.palette.text.contrast,
            backgroundColor: theme.palette.error.main,
        },
        infoIcon: {
            height: ".65em",
            width: ".65em",
            background: "transparent",
        },
    };
});

// stolen off stackoverflow... seems to work... ?
const urlPattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/i;

export const FillRequired: React.FC<ISourceUrlProps> = ({
    hide = true,
    required,
    navigate,
}) => {
    const inputRef = useRef<HTMLInputElement>();
    const [errors, setErrors] = useState<Errors>({
        sourceUrl: "",
    });
    const { sourceUrl } = useWidgetContext();
    const styles = useStyles(errors);

    const updateError = useCallback((key: RequireParams, error = "") => {
        setErrors((curErrors) => curErrors[key] !== error ? ({
            ...curErrors,
            [key]: error,
        }) : curErrors);
    }, [setErrors]);

    const {
        requiresSourceUrl,
    } = useMemo(() => {
        const requiresSourceUrl = required.includes("sourceUrl");
        return {
            requiresSourceUrl,
        };
    }, [required]);

    const handleApply = (key: RequireParams, val?: string) => {
        if (!val) {
            navigate("?", {
                ...navOptions,
                removeQuery: [key],
            });
        } else {
            switch (key) {
                case "sourceUrl": {
                    if (!urlPattern.test(val)) {
                        updateError("sourceUrl", "You must enter a valid URL!");
                        return;
                    }
                }
            }

            navigate({
                query: {
                    [key]: val,
                },
            }, navOptions);
        }
    };

    return (
        <Fragment>
            {requiresSourceUrl && (!sourceUrl || !hide) && (
                <Grid
                    container
                    spacing={1}
                    className={styles.contentContainer}
                >
                    <Grid item xs={6}>
                        <Paper
                            elevation={3}
                            className={styles.content}
                        >
                            <Typography variant="title">
                                {"Get Started"}
                            </Typography>
                            <Typography variant="body1" paragraph>
                                To begin using this component, you will need to enter
                                the source url of the page you wish to use it on.  This
                                is <i><b>required</b></i> so that the app can reliably
                                tell where it is being used.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Failure to use a source url may cause the app to not function
                                as expected
                            </Typography>
                            <form onSubmit={(e) => { e.preventDefault(); }}>
                                <Box mb={"10px"}>
                                    <Input
                                        label="URL"
                                        placeholder="URL"
                                        background="contrast"
                                        inputRef={inputRef}
                                        defaultValue={sourceUrl}
                                        error={errors.sourceUrl}
                                        onChange={() => {
                                            updateError("sourceUrl", "");
                                        }}
                                        postLabel={(
                                            <Tooltip
                                                title="URL Example: http://www.google.com/"
                                                placement="right-end"
                                                classes={{
                                                    tooltip: clsx({
                                                        [styles.tooltip]: true,
                                                        [styles.tooltipError]: !!errors.sourceUrl,
                                                    }),
                                                }}
                                                open={!!errors.sourceUrl || undefined}
                                            >
                                                <InfoIcon className={styles.infoIcon} />
                                            </Tooltip>
                                        )}
                                    />
                                </Box>
                                <PrimaryButton
                                    type="submit"
                                    onClick={() => {
                                        const val = inputRef.current?.value;
                                        handleApply("sourceUrl", val);
                                    }}
                                >
                                    {"APPLY"}
                                </PrimaryButton>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            )}
        </Fragment>
    );
};