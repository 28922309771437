import React, { Fragment, ReactNode, Children } from "react";
import { ColFullWidth } from "./GridCol";
import { isReactElement } from "./shared";

const gridItems = ["HalfCol", "FullCol", "GridItem"];
const ignoreItems = ["Display"];

const applyOnContiguousMatches = (
    children: ReactNode[],
    isGridItem: (child: ReactNode) => boolean,
    wrap: (child: ReactNode[], idx: number) => ReactNode,
): React.ReactNode[] => {
    const currentGroup: ReactNode[] = [];
    const result: ReactNode[] = [];

    const wrapCurrent = (idx: number) => {
        if (currentGroup.length > 0) {
            result.push(wrap([ ...currentGroup ], idx));
            currentGroup.length = 0;
        }
    };

    for (const [idx, child] of children.entries()) {
        if (!isGridItem(child)) {
            currentGroup.push(child);
        } else {
            wrapCurrent(idx);
            result.push(child);
        }
    }

    if (currentGroup.length > 0){
        result.push(wrap(currentGroup, children.length));
    }

    return result;
};

const containContiguous = (children: ReactNode[]) => {
    return children
        ? (
            applyOnContiguousMatches(
                children,
                // Is grid item?
                (child) => {
                    if (isReactElement(child)) {
                        if (child.props?.mdxType) {
                            return (
                                gridItems.includes(child.props.mdxType)
                                || ignoreItems.includes(child.props.mdxType)
                            );
                        }
                    }
                    return false;
                },
                // Wrap non-grid items
                (matches, idx) => (
                    <ColFullWidth key={`contained-group-${idx}`}>{matches}</ColFullWidth>
                ),
            )
        ) : (
            children
        );
};

export const MakeGridItems: React.FC = ({ children }) => {
    return (
        <Fragment>
            {containContiguous(Children.toArray(children))}
        </Fragment>
    );
};