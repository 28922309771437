import dayjs, { Dayjs } from "dayjs";
import startCase from "lodash/startCase";
import camelCase from "lodash/camelCase";
import { dateFormat, dtFormat, internalDateFormat } from "~/meta";

export const transformToPhone = (val: string, punct = false): string => {
    if (!val || typeof val !== "string") return val;
    val = val.replace(/[^\d]+/g, "");
    const pattern = val.length <= 7
        ? /^(\d{3})(\d{1,})/
        : val.length <= 10
            ? /^(\d{1,3})(\d{3})(\d{1,})/
            : /^(\d{1,3})(\d{3})(\d{3})(\d{4,})/;
    const matches = pattern.exec(val);
    if (!matches) return val;

    const sections = [];
    const values = matches.slice(1).filter(Boolean) || [];

    if (values.length > 3) sections.push([
        "+",
        values.shift(),
    ].join(""));

    if (values.length > 2) sections.push([
        punct && "(",
        values.shift(),
        punct && ")",
    ].filter(Boolean).join(""));

    sections.push(values.slice(0).join(punct ? "-" : " "));
    return sections.filter(Boolean).join(" ");
};

export const prettyNumber = (val: string | number, prec = 0): string => {
    const parsedVal = typeof val === "string"
        ? parseInt(val, 10)
        : val;
    if (parsedVal !== 0 && (!parsedVal || isNaN(parsedVal))) return "";
    return parsedVal.toFixed(prec)
        .replace(/(\d{1,3}(?!,))(?=(\d{3})+(?!\d))/g, "$1,");
};

export const transformToCurrency = (val: string | number, prec = 2): string => {
    const transformed = prettyNumber(val, prec);
    return transformed && "$" + transformed || "";
};

export const formatPostalCode = (val?: string | null): string => {
    if (!val) return "";
    return val.replace(/\s+/g, "").split(/(.{3})/).join(" ");
};

export const toProperCase = (val?: string | number | null): string => {
    const checkVal = (val !== null && val !== undefined && (
        typeof val === "number"
            ? val.toString()
            : val
    )) || "";
    return startCase(camelCase(checkVal));
};

const doDateTransform = (fromPattern: string, toPattern: string, val: string | Dayjs): string => {
    const dt = typeof val === "string" ? dayjs(val, fromPattern, true) : val;
    if (dt.isValid()) return dt.format(toPattern);
    return "";
};
export const dateToOutput = (val: string | Dayjs): string => (
    doDateTransform(internalDateFormat, dateFormat, val)
);
export const timestampToDateTime = (val: string | Dayjs): string => (
    doDateTransform(internalDateFormat, dtFormat, val)
);
export const dateToInternal = (val: string | Dayjs): string => (
    doDateTransform(dateFormat, internalDateFormat, val)
);

export const dateTransformer = (pattern: string) => (
    (val?: string | number) => (
        (
            val && typeof val === "string"
                ? dayjs(val).format(pattern)
                : val?.toString()
        ) || ""
    )
);