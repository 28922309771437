import React from "react";
import Grid, { GridProps } from "@material-ui/core/Grid";

export const GridItem: React.FC<GridProps> = (props) => (
    <Grid item {...props} />
);

export const ColHalfWidth: React.FC<GridProps> = (props) => (
    <GridItem xs={12} lg={6} {...props} />
);

export const ColFullWidth: React.FC<GridProps> = (props) => (
    <GridItem xs={12} {...props} />
);