import { ProductTypes, PRODUCTS } from "@autocorp/gatsby-source-askava";

export type { ProductTypes };

export type {
    IDocCategory,
    IDocSubcategory,
    IDocMeta,
} from "@autocorp/gatsby-source-askava";
export type AvaDoc = NonNullable<GatsbyTypes.DocsBySlugQuery["mdx"]>;
export type AskAvaMeta = NonNullable<AvaDoc["askAva"]>;

export const productNames = PRODUCTS as unknown as string[];

export const productTitles: Record<ProductTypes, string> = {
    creditTool: "AVA Credit",
    tradeTool: "AVA Trade",
};