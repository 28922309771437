import { HtmlTemplateContent } from "~/model/html";
import { captureError, captureException } from "@utils/sentry";
import { stringifyError } from "@utils/errors";
import interpolate from "lodash/template";

export const assetTemplate = (
    content: string,
    widgetAsset: HtmlTemplateContent,
    template?: string | boolean,
): [string | null, string] => {
    if (template) {
        try {
            if (typeof template === "string" && !(template in widgetAsset)) {
                throw "Invalid template section defined";
            }
            return [null, interpolate(content.trim())(widgetAsset)];
        } catch (err) {
            const extra = {
                widgetAsset,
                template,
            };
            const capture = (msg: string) => {
                captureError(msg, {
                    error: err,
                    extra,
                });
            };
            if (typeof err === "string") {
                capture("Failed to interpolate asset template");
                return [err as string, ""];
            }
            captureException(err, extra);
            return [stringifyError(err), ""];
        }
    }
    return [null, content.trim()];
};