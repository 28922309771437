import React from "react";

import { LooseTabbedNav } from "~/components/TabbebNav";
import { useDocCollection } from "./hooks/use-doc-collection";
import { useWidgetDetails } from "./hooks/use-widget-details";
import { DocsLayoutComponent } from "./types";


export const TitleNav: DocsLayoutComponent = ({
    location,
    pageProps: {
        data,
        pageContext,
    },
}) => {
    const { catIndex } = useDocCollection();
    const { product: curProduct, prefix } = useWidgetDetails(data.mdx);

    const curDocCategory = data.mdx.frontmatter?.category;
    const curDocSubCategory = data.mdx.frontmatter?.subCategory;

    const curSubs = (
        curDocCategory &&
        catIndex[prefix][curDocCategory]?.subs ||
        {}
    );

    const curDocs = (
        curDocSubCategory &&
        curSubs[curDocSubCategory] ||
        []
    );

    if (curDocs.length > 1) {
        return (
            <LooseTabbedNav
                tabs={curDocs.map(({ id, title, path }) => ({
                    path: `${path}${curProduct ? `?product=${curProduct}` : ""}`,
                    title,
                    selected: data.mdx.id === id,
                    keepQuery: true,
                    saveState: true,
                }))}
            />
        );
    }

    return null;
};