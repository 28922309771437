export const ClientApiDocLinks = {
    "integrated-platform": [
        {
            name: "Client Side Marketing",
            href: "https://github.com/autocorptech/documentation/wiki/Client-Side-Marketing",
        },
        {
            name: "Lifecycle Events API",
            href: "https://github.com/autocorptech/documentation/wiki/Lifecycle-Events-API",
        },
    ],
    "data-layer": [
        {
            name: "Datalayer",
            href: "https://github.com/autocorptech/documentation/wiki/Datalayer",
        },
        {
            name: "Datalayer Examples",
            href: "https://github.com/autocorptech/documentation/wiki/Datalayer-Examples",
        },
    ],
};