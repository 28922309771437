import React, { useMemo } from "react";
import { MarketingGallery } from "./MarketingGallery";
import { MarketingApiLinks, MarketingApiLinksSection } from "./MarketingApiLinks";
import { MarketingAnalyticsGuide, MarketingAnalyticsGuideSection } from "./MarketingAnalyticsGuide";
import {
    CreditInlinePreviewSection,
    CreditInlineSidebarPreviewSection,
    CreditBannerPreviewSection,
    CreditEmbedPreviewSection,
    CreditStaticEmbedPreviewSection,
    CreditButtonCtaPreviewSection,
    TradeInlinePreviewSection,
    TradeInlineSidebarPreviewSection,
    TradeBannerPreviewSection,
    TradeEmbedPreviewSection,
    TradeStaticEmbedPreviewSection,
    TradeButtonCtaPreviewSection,
} from "./InstallGuidePreviews";

type CustomComponent = React.FC<Record<never, never>>;
const customComponent: Record<string, CustomComponent> = {
    MarketingGallery,
    MarketingApiLinksSection,
    MarketingAnalyticsGuideSection,
    CreditInlinePreviewSection,
    CreditInlineSidebarPreviewSection,
    CreditBannerPreviewSection,
    CreditEmbedPreviewSection,
    CreditStaticEmbedPreviewSection,
    CreditButtonCtaPreviewSection,
    TradeInlinePreviewSection,
    TradeInlineSidebarPreviewSection,
    TradeBannerPreviewSection,
    TradeEmbedPreviewSection,
    TradeStaticEmbedPreviewSection,
    TradeButtonCtaPreviewSection,
};

export const useCustomComponent = (name?: string | null): CustomComponent => (
    useMemo(() => (
        name && customComponent[name] || function NullCustomComponent() {
            return null;
        }
    ), [name])
);