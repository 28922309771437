import gql from "graphql-tag";

export const GetWidgetDetails = gql`
    query GetWidgetDetails($widgetId: String!) {
        getWidgetDetails(widgetId: $widgetId) {
            company {
                id
                name
                archived
            }
        }
    }
`;