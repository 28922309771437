import React, { Fragment, useRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";

import { InstallCatalog, CatalogManager } from "@autocorp/ava/ctas/catalog";

import { Typography } from "~/components/Typography";
import { PrimaryButton } from "~/components/Button";
import { Input } from "~/components/Input";

import { navigate, INavigateFn } from "@utils/navigate";
import { getWidgetDetails } from "@api/widget";
import { ctaCatalogClient } from "@api/ctas";
import { getErrorMessage } from "@utils/errors";
import { useWidgetDetails } from "./hooks/use-widget-details";
import { useWidgetContext } from "./state";

import type { WindowLocation } from "~/model/view";
import type { AvaDoc } from "~/model/docs";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "center",
        alignItems: "center",
    },
    modal: {
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "center",
        alignItems: "center",
        userSelect: "none",
        outline: 0,
        padding: 30,
    },
    loader: {
        width: 300,
        height: 300,
    },
    idContent: {
        width: 450,
    },
    inputContainer: {
        width: "100%",
    },
    input: {
        width: "100%",
    },
    buttonContainer: {
        width: "100%",
    },
    button: {
        width: "100%",
    },
}));

interface IIdModalProps {
    location: WindowLocation;
    avaDoc: AvaDoc;
}

const doNavigate: INavigateFn = (to, options) => {
    return navigate(to, {
        replace: true,
        saveState: true,
        keepQuery: true,
        ...options,
    });
};

const removeWidgetId = () => {
    doNavigate("?", {
        removeQuery: ["widgetId"],
        state: {
            verifiedWidget: "",
            companyName: undefined,
        },
    });
};

export const IdModal: React.FC<IIdModalProps> = ({ children, location, avaDoc }) => {
    const {
        catalogManager,
        fetchCtaCatalog,
        assetsUrl,
        widgetUrl,
    } = useWidgetContext();
    const { widgetId, version } = useWidgetDetails(avaDoc);
    const inputRef = useRef<HTMLInputElement>();
    const { verifiedWidget } = location.state || {};

    const verified = [verifiedWidget, widgetId].every(Boolean) && (
        verifiedWidget === widgetId
    ) && (
        version === 1 ||
        (version >= 2 && !!catalogManager)
    );
    const verifying = !!widgetId && !verified;
    // const verifying = false;

    const [error, setError] = useState("");

    const styles = useStyles();

    useEffect(() => {
        if (verifying && widgetId) {
            (async () => {
                try {
                    console.log({ verified, verifying, widgetId, verifiedWidget, catalogManager });
                    const company = await getWidgetDetails(widgetId);
                    if (version === 2) await fetchCtaCatalog();

                    if (!company) {
                        removeWidgetId();
                    } else {
                        doNavigate("?", {
                            state: {
                                verifiedWidget: widgetId,
                                companyName: company.name,
                            },
                        });
                    }
                } catch (err) {
                    setError(getErrorMessage(err));
                    removeWidgetId();
                }
            })();
        }
    }, [
        assetsUrl,
        widgetUrl,
        version,
        verified,
        verifying,
        verifiedWidget,
        widgetId,
        catalogManager,
        fetchCtaCatalog,
    ]);

    if (verified) return (
        <Fragment>
            {children}
        </Fragment>
    );

    return (
        <Modal
            open
            className={styles.container}
            contentEditable={false}
        >
            <Paper
                elevation={5}
                className={clsx({
                    [styles.modal]: true,
                    [styles.idContent]: !verifying,
                    [styles.loader]: verifying,
                })}
            >
                {verifying ? (
                    <CircularProgress size={"50%"} />
                ) : (
                    <Fragment>
                        <Box pb={"12px"}>
                            <Typography variant="dialogTitle">
                                {"Widget ID Missing"}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            textAlign="center"
                        >
                            The dealer&apos;s Widget ID is needed to proceed.
                            <br />
                            This will have been provided in the documentation
                            introductory email, and can also be retrieved
                            from the dealer&apos;s admin portal.

                            <p><b>
                                Please contact the dealer administrator for
                                additional information
                            </b></p>
                        </Box>
                        <Box
                            className={styles.inputContainer}
                            pb="40px"
                        >
                            <Input
                                className={styles.input}
                                label="Widget ID"
                                placeholder="Widget ID"
                                defaultValue={widgetId}
                                inputRef={inputRef}
                                onChange={() => {
                                    setError("");
                                }}
                            />
                        </Box>
                        <Box
                            className={styles.buttonContainer}
                        >
                            <PrimaryButton
                                className={styles.button}
                                onClick={(e) => {
                                    e.preventDefault();
                                    const val = inputRef.current?.value;
                                    if (val) doNavigate({
                                        query: {
                                            widgetId: val,
                                        },
                                    });
                                }}
                                type="button"
                            >
                                {"SAVE"}
                            </PrimaryButton>
                        </Box>
                        {error && (
                            <Box width="100%" mt="5px">
                                <Typography color="error">
                                    {error}
                                </Typography>
                            </Box>
                        )}
                    </Fragment>
                )}
            </Paper>
        </Modal>
    );
};