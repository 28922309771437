import React, { Fragment, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { LeftNavWidget } from "~/components/Widget";
import { LooseLink } from "~/components/Link";

import { normalizeUrl } from "@utils/url";
import { escapeForRegex } from "@utils/regexp";
import { mdxBaseRoutes } from "~/meta";
import { DocsLayoutComponent } from "./types";


import GettingStartedIcon from "~/assets/vectors/power.svg";
import ApiIcon from "~/assets/vectors/tool.svg";
import MarketingIcon from "~/assets/vectors/target.svg";
// import CtaIcon from "~/assets/vectors/target.svg";
// import EmbedIcon from "~/assets/vectors/layout.svg";

type Categories = typeof mdxBaseRoutes["categories"];
type CategoryMeta = Categories[keyof Categories];

type CategoryNavIcons = {
    [K in keyof Categories]: typeof import("*.svg").default;
};

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: 13,
    },
}));

const categoryIcons: CategoryNavIcons = {
    "Install Guide": GettingStartedIcon,
    "API": ApiIcon,
    "Marketing": MarketingIcon,
    // "CTA": CtaIcon,
};

interface ICategoryLinkProps {
    base: string;
    prefix?: string;
    catName: string;
    category: CategoryMeta;
    active?: boolean;
}

const CategoryLink: React.FC<ICategoryLinkProps> = ({
    base,
    prefix = "",
    catName,
    category,
    active,
}) => {
    const styles = useStyles();
    const catPath = useMemo(() => (
        normalizeUrl(base, prefix, category.base)
    ), [base, prefix, category]);

    const Icon = categoryIcons[catName as keyof Categories];
    return (
        <LooseLink
            to={catPath}
            removeQuery={["product"]}
            keepQuery
            saveState
        >
            <LeftNavWidget
                active={active}
                label={catName}
                decorator={<Icon />}
                classes={{
                    label: styles.label,
                }}
            />
        </LooseLink>
    );
};

export const CategoryNav: DocsLayoutComponent = ({
    location,
    pageProps: {
        data,
        pageContext,
    },
}) => {
    const curDocCategory = data.mdx.frontmatter?.category;
    const curPath = pageContext.askAvaPath;
    const prefix = data.mdx.askAva?.prefix;

    const base = useMemo(() => (
        location.pathname.replace(
            new RegExp(`${escapeForRegex(curPath)}.*`, "ig"),
            "",
        )
    ), [location, curPath]);

    return (
        <Fragment>
            {Object.entries(mdxBaseRoutes.categories)
                .map(([catName, category]) => (
                    <CategoryLink
                        key={`widget-category-nav-${catName}`}
                        base={base}
                        prefix={prefix}
                        catName={catName}
                        category={category}
                        active={curDocCategory === catName}
                    />
                ))
            }
        </Fragment>
    );
};