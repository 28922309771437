import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import type { MDXProviderComponents } from "@mdx-js/react";

const useStyles = makeStyles(() => ({
    table: {
        borderCollapse: "collapse",
        "& th, & td": {
            border: "1px solid black",
            padding: 5,
        },
    },
}));

export const Table: MDXProviderComponents["table"] = ({ children, ...props }) => {
    const styles = useStyles();
    return (
        <table className={styles.table} {...props}>
            {children}
        </table>
    );
};