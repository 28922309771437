import React, { useMemo, useState, useCallback } from "react";
import { MDXProvider } from "@mdx-js/react";
import template from "lodash/template";

import { useAvaMetadata } from "@autocorp/gatsby-source-askava";
import {
    InstallCatalog,
    IInstallCatalogArgs,
    CatalogManager,
} from "@autocorp/ava/ctas/catalog";

import { HtmlTemplateContent } from "~/model/html";
import { ProductTypes } from "~/model/docs";
import { getErrorMessage } from "@utils/errors";
import { isEqual } from "@utils/obj";
import { ctaCatalogClient } from "@api/ctas";

import {
    IWidgetDesc,
    ITemplateState,
    WidgetContext,
    defaultWidgetState,
} from "./context";
import { components } from "../mdx";

interface IProviderProps extends HtmlTemplateContent, IWidgetDesc {
    version: number;
    widgetId?: string;
    product?: ProductTypes;
    sourceUrl?: string;
    group?: string;
    type?: string;
}

type InstallCatalogArgs = OmitKeys<IInstallCatalogArgs, "client">;

let installCatalog: InstallCatalog;

const checkCatalog = (args: InstallCatalogArgs) => {
    return installCatalog && isEqual(installCatalog.args, args);
};
const getInstallCatalog = (args: InstallCatalogArgs) => {
    if (checkCatalog(args)) return installCatalog;

    return installCatalog = new InstallCatalog({
        ...args,
        client: ctaCatalogClient,
    });
};

let cachedCatalogManager: CatalogManager;
const getCatalogManager = (args: InstallCatalogArgs) => {
    if (!checkCatalog(args)) return;
    return cachedCatalogManager;
};

export const WidgetProvider: React.FC<IProviderProps> = ({
    children,
    pointer,
    support,
    prerequisite,
    body,
    widgetId,
    product,
    sourceUrl,
    version,
    group,
    type,
    ...state
}) => {
    const { avaAssetsUrl, avaWidgetUrl } = useAvaMetadata();

    const catalogArgs = useMemo<InstallCatalogArgs>(() => ({
        widgetId: widgetId || "",
        assetsUrl: avaAssetsUrl,
        widgetUrl: avaWidgetUrl,
    }), [avaAssetsUrl, avaWidgetUrl, widgetId]);

    const [catalogManager, setCatalogManager] = useState<ITemplateState["catalogManager"]>(() => (
        getCatalogManager(catalogArgs)
    ));

    const fetchCtaCatalog = useCallback(async () => {
        const catalogManager = cachedCatalogManager = (
            await getInstallCatalog(catalogArgs).fetchCatalog()
        );
        setCatalogManager(catalogManager);
    }, [catalogArgs]);

    const templated = useMemo<ITemplateState>(() => {
        const templateState = {
            widgetId,
            product,
            sourceUrl: sourceUrl ? encodeURIComponent(sourceUrl) : "",
        } as ITemplateState;

        if (version === 2) {
            if (catalogManager) {
                try {
                    if (!product || !type) {
                        throw new Error("There is no product or type defined!!");
                    }

                    switch (group?.toLowerCase()) {
                        case "cta": {
                            const ctaVersion = catalogManager
                                .getCtaDetails(product, type)
                                .getCtaVersion();
                            templateState.pointer = ctaVersion.div();
                            templateState.prerequisite = ctaVersion.script();
                            break;
                        }
                        case "embed": {
                            const embedDetails = catalogManager.getEmbedDetails(product);
                            templateState.prerequisite = embedDetails.script();
                            if (type === "embedDynamic") {
                                templateState.pointer = embedDetails.div();
                            } else if (type === "embedStatic") {
                                templateState.pointer = embedDetails.staticIframe(templateState.sourceUrl!);
                            }
                            break;
                        }
                    }
                } catch (err) {
                    templateState.pointer = getErrorMessage(err);
                }
            }
            return templateState;
        } else {
            return Object.entries(
                {pointer, support, body, prerequisite},
            ).reduce((acc, [key, val]) => {
                return Object.assign(acc, {
                    [key]: !widgetId ? "No widget id!" : (
                        template(val, {
                            interpolate: /<%=([\s\S]+?)%>/g,
                        })(acc)
                    ),
                });
            }, templateState);
        }
    }, [
        version,
        group,
        type,
        catalogManager,
        pointer,
        support,
        prerequisite,
        body,
        widgetId,
        product,
        sourceUrl,
    ]);

    return (
        <WidgetContext.Provider
            value={Object.assign<
                ITemplateState,
                typeof state,
                ITemplateState,
                Partial<ITemplateState>
            >(
                defaultWidgetState,
                state,
                templated,
                {
                    assetsUrl: avaAssetsUrl,
                    widgetUrl: avaWidgetUrl,
                    sourceUrl,
                    catalogManager,
                    fetchCtaCatalog,
                },
            )}
        >
            <MDXProvider components={components}>
                {children}
            </MDXProvider>
        </WidgetContext.Provider>
    );
};
