import React, { useMemo, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "@hooks/use-location";
import { captureError } from "@utils/sentry";
import { normalizeUrl } from "@utils/url";
import { LooseLink } from "~/components/Link";
import { useDocCollection } from "../hooks/use-doc-collection";

const useStyles = makeStyles((theme) => ({
    link: {
        // textDecoration: "underline",
    },
}));

interface IDocLinkProps {
    /**
     * Page to navigate to.
     * Can be absolute or relative.
     */
    to: string;
    /**
     * Navigating to another doc page?
     * @default true
     **/
    internal?: boolean;
}

const checked: Record<string, boolean> = {};

export const DocLink: React.FC<IDocLinkProps> = ({
    to,
    internal = true,
    children,
}) => {
    const location = useLocation();
    const { base, docs } = useDocCollection();
    const pages = useMemo(() => (
        docs.map((doc) => (
            doc.path
        ))
    ), [docs]);
    const styles = useStyles();

    const isAbsolute = to.startsWith("http") || to.startsWith("/");
    const toPath = normalizeUrl(isAbsolute ? to : `${base}/${to.replace(/^[0-9]+-/, "")}`);

    if (internal && !checked[toPath] && typeof window !== "undefined") {
        checked[toPath] = true;
        if (!pages.includes(toPath)) {
            const msg = [
                `Invalid doc link specified in mdx component: ${toPath}`,
                `From: ${location.pathname}`,
            ].join("\n");
            const err = new Error(msg);
            captureError(msg, {
                error: err,
                extra: {
                    location: location.pathname,
                    to: toPath,
                },
            });
        }
    }

    return (
        <LooseLink
            to={toPath}
            className={styles.link}
            color="initial"
            underline="always"
            keepQuery
            saveState
        >
            {children}
        </LooseLink>
    );
};