import React, { Fragment, useMemo } from "react";
import get from "lodash/get";

import { useWidgetContext } from "../state";

interface IStateValueProps {
    withKey: string;
    from: string;
}

export const StateValue: React.FC<IStateValueProps> = ({ withKey, from }) => {
    const widgetState = useWidgetContext();
    const fromObj = get(widgetState, from, undefined);
    const statePath = get(widgetState, withKey, undefined);

    const outputValue = useMemo(() => {
        const newVal = get(fromObj, statePath!, undefined);

        if (newVal === undefined) {
            throw new Error(`Invalid state value selected ('${from}.${statePath}')!!`);
        }

        return newVal;
    }, [from, fromObj, statePath]);


    return (
        <Fragment>
            {outputValue}
        </Fragment>
    );
};