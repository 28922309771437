import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";

interface ILeftRuleStyleProps {
    marginWidth?: number;
}

const useStyles = makeStyles<Theme, ILeftRuleStyleProps>({
    cssGrid: {
        display: "grid",
        gridTemplateColumns: ({ marginWidth }) => `${marginWidth}px minmax(0, 1fr)`,
    },
});

export interface ILeftRuleProps extends ILeftRuleStyleProps {}

export const LeftRule: React.FC<ILeftRuleProps> = ({ marginWidth = 124, children }) => {
    const styles = useStyles({ marginWidth });

    return (
        <div className={styles.cssGrid}>
            {children}
        </div>
    );
};